import React from 'react'
import CardItem from './CardItem'
import './Cards.css'

function Cards() {
  return (
    <div className='cards'>
        <div className='cards__container'>
            <div className='cards__wrapper'>
              <h1>Unsere Partner</h1>
                <ul className='cards__items'>
                    <CardItem 
                    src='/referenzen/glass.jpg'
                    text='Innovative Entwicklung, kompetente Beratung, detaillierte Planung und meisterhafte Ausführung'
                    label='Glas Marte'
                    path='https://www.glasmarte.at/de?utm_medium=gmb&utm_source=google'
                    />
                    <CardItem 
                    src='/referenzen/alu.jpg'
                    text='Alu Alprem ist Ihr Spezialist für hochwertige und energieeffiziente Bauelemente aus Aluminium'
                    label='Alualprem'
                    path='https://www.alprem.si/de/'
                    />
                </ul>
                
                <ul className='cards__items'>
                    <CardItem 
                    src='/referenzen/hilti.jpg'
                    text='Zuverlässige Dübel für Profis'
                    label='Hilti'
                    path='https://www.hilti.de'
                    />
                    <CardItem 
                    src='/referenzen/metal.jpeg'
                    text='Herstellung von Metallstrukturen und Metallprodukten'
                    label='Metallmar'
                    path='https://metallmar.si/#main'
                    />
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Cards
